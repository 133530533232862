import React from "react";

import { objectforest } from "assets/data/objectforest";

export default function Objectforest() {
  return (
    <div className="objectiveforest inner-pages pb-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-theme-heading"> Why this initiative? </h3>
            <p className="initive-paratext">
              {" "}
              The journey of BGSW over the past 25 years has been nothing but
              glorious. And as we look at the next 25 years ahead of us, it is
              crucial we also leave a legacy that addresses ecological and
              social sustainability for generations to come. This initiative
              was born last year and continues doing that.
            </p>
          </div>
          <div className="row object-section-row">
            {objectforest.map((item) => {
              return (
                <div className="col-md-2 col-sm-4" key={item.id}>
                  <div className="object-box-wrapper">
                    <div className="object-img">
                      <img
                        data-src={item.img}
                        className="lazyload"
                        alt={item.alt}
                      />
                    </div>
                    <div className="object-content">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
