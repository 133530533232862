import React from "react";

import vadorashramam from "assets/images/bosch/vadora-ashramam.png";

import volunteerhand from "assets/images/bosch/helping-hand-voluteer.png";

import atftree from "assets/images/bosch/ATF_Image.png";

export default function PlantationVideo() {
  return (
    <div className="project-execute inner-pages">
      <div className="container">
        <div className="row mobi-column-rever">
          <div className="col-md-7">
            <iframe
              width="100%"
              height="360"
              src="https://www.youtube.com/embed/VlUoHUImJM4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="col-md-5">
            <h3 className="title-theme-heading">
              A sustainable initiative that will live on for generations to come
            </h3>
            <p className="execute-p-content">
              The multicity drive was received fabulously by Boschlers who come
              together to do their part in planting 25 thousand saplings across
              Hyderabad, Coimbatore, Bangalore and Pune.
            </p>
            <p className="execute-p-content">
              Watch the video for a glimpse into the energy, enthusiasm, spirit,
              and heart that went into the drive.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
