import React from "react";

import vadorashramam from "assets/images/bosch/vadora-ashramam.png";

import volunteerhand from "assets/images/bosch/helping-hand-voluteer.png";

import atftree from "assets/images/bosch/ATF_Image.png";

export default function GrowthVideo() {
  return (
    <div className="project-execute inner-pages">
      <div className="container">
        <div className="row mobi-column-rever">
          <div className="col-md-7">
            <iframe
              width="100%"
              height="360"
              src="https://www.youtube.com/embed/fhXrdxS29Uk"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="col-md-5">
            <h3 className="title-theme-heading">
              Watch how our Forests look after months of plantation!
            </h3>
            <p class="execute-p-content">
              Observe the transformation of our plantation. Witness the gradual
              flourishing forest and experience the development of a diverse
              ecosystem. Watch the video now!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
