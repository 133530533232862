import React from 'react';

import tab22 from 'assets/images/tabs/tab22.png';

const ProjectTabSection22 = () => {
  return (
    <>
      <div className='tab-wrapper'>
        <div className='img-wrapper img-no-wrapper'>
          <img data-src={tab22} className='lazyload' alt='tab2' />
        
        </div>
      </div>
    </>
  );
};

export default ProjectTabSection22;
