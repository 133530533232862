import React from "react";
import { Link } from "gatsby";
import vadorashramam from "assets/images/bosch/vadora-ashramam.png";

import volunteerhand from "assets/images/bosch/helping-hand-voluteer.png";

import atftree from "assets/images/bosch/ATF_Image.png";

export default function Projectexecute() {
  return (
    <div className="project-execute inner-pages">
      <div className="container">
        <div className="row mobi-column-rever">
          <div className="col-md-7">
            <img
              className="vadora-img"
              src={vadorashramam}
              alt="vadora ashramam"
            />
          </div>
          <div className="col-md-5">
            <h3 className="title-theme-heading">
              How was this project executed?
            </h3>
            <p className="execute-p-content">
              These mini-forests were created using FBH's{" "}
              <span>
                {" "}
                proprietary Heartyculture High Density (HCHD) plantation method.{" "}
              </span>{" "}
              This involved extensive
              <span>
                {" "}
                soil reconstitution using Activated Biochar, Vermicompost, Cow
                manure and cocopeat.{" "}
              </span>{" "}
              The selection of species, besides being native and endemic, were
              such that they continue to support one another through an{" "}
              <span>
                {" "}
                extensive root network. Within 1 year, the saplings have grown
                to a height of 10-15 ft, which is several times the growth of a
                regular plantation.{" "}
              </span>{" "}
              We believe that in a span of two years the forest would be
              impenetrable with no sunlight reaching the soil.
            </p>
          </div>
        </div>

        <div className="row pad60-0">
          <div className="col-md-6 pad80-0">
            <h3 className="title-theme-heading">
              How can you be part of this initiative?
            </h3>
            <p className="execute-p-content">
              In case you've missed the drive, you can track the health and
              growth of the mini-forests that have been planted in your name for
              a year in the section ‘Track Your Forests’. Each of the BGSW
              locations will run periodic trips to visit the plantation site,
              where you can participate in nurturing and maintenance of the
              saplings.
            </p>
            <p className="execute-p-content">
              Want to work with a team that makes an impact- and participate in
              nurturing and maintenance of the saplings? Share your interest{" "}
              <Link
                to="https://www.google.com/url?q=https://forms.office.com/Pages/ResponsePage.aspx?id%3DGR7lCsgHS067bWSO5YQQ9D5PGsYPRyNOpmx206zXl_BUMTdTVUM4RE81S1cyMzA0VkpGRlhBVVFOUi4u&sa=D&source=docs&ust=1684935297631331&usg=AOvVaw2hnJfD0Um7BbFdXwXUr9-V"
                target="_blank"
                rel="noreferrer"
              >
                here
              </Link>
            </p>
          </div>

          <div className="col-md-6">
            <img
              className="tree-logo-custom"
              src={atftree}
              alt="vadora ashramam"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
