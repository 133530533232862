import React, { useState, useRef, useEffect } from "react";

import { Accordion } from "react-bootstrap";

// import Carousel from 'react-multi-carousel';

// import 'react-multi-carousel/lib/styles.css';

import ProjectTabSection21 from "components/home/project/ProjectTabSection21";
import ProjectTabSection22 from "components/home/project/ProjectTabSection22";
import ProjectTabSection23 from "components/home/project/ProjectTabSection23";

// data

import { unique } from "assets/data/projectunique";

export default function Projectsunique() {
  const [tabName, setTabName] = useState("tab21");

  const stateCarousel = useRef(null);

  const [mapCurrentTab] = useState(21);

  const [isMobile, setIsMobile] = useState(false);

  const changeTabName = (name) => {
    setTabName(name);
  };

  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()} className="me-3">
            <i className="icon icon-tree-leaf-left"></i>
          </button>
          <button onClick={() => next()}>
            <i className="icon icon-tree-leaf-right"></i>
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <div className="project-section project-unique">
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="title-theme-heading">
                {" "}
                Why is this project unique?{" "}
              </h3>
              <p className="describe">
                {" "}
                BGSW wants to kick start this new era with enduring commitment
                to sustainability, diversity and longevity. This initiative
                brought all the BGSW associates together to plant and be
                encouraged and aware of how a small step like planting a sapling
                can bring a tremendous change in our environment. Hence, this
                plantation drive brought forth these themes as below:

              </p>
              <div className="accordian-tab-section">
                <div className="row">
                  <div className="aco-tab-section">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab21");
                          }}
                        >
                          Sustainability
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            Choice of species (Native, Forest variety,
                            endangered species), Plantation Style (High density
                            mini-Forest plantation) No usage of artificial
                            pesticides and fertilizers.
                          </p>

                          {isMobile ? <ProjectTabSection21 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab22");
                          }}
                        >
                          Longevity
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            Choice of plantation site (fully protected forever
                            making the forest stand for several decades)
                          </p>

                          <p>
                            Involvement of local communities, school children to
                            maintain
                          </p>

                          <p>
                            Active monitoring of sapling growth 2 years using
                            Geotagging, watering, weeding and nutrient
                            management.
                          </p>

                          {isMobile ? <ProjectTabSection22 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab23");
                          }}
                        >
                          Diversity
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            {" "}
                            Through this initiative we reinstate our commitment
                            to{" "}
                            <span>
                              {" "}
                              sustainability, diversity, and longevity.{" "}
                            </span>{" "}
                            The plantation drive will address these aspects in
                            the following manner:
                          </p>
                          <p>
                            With at least 40 varieties of native tree species
                            with inclusion of rare, endangered, and threatened
                            varieties. Our past experience shows that these
                            forests quickly become home to several varieties of
                            bees, birds, and butterflies.
                          </p>
                          {isMobile ? <ProjectTabSection23 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  {!isMobile ? (
                    <div className="aco-tab-content-section">
                      <div>
                        {(() => {
                          switch (tabName) {
                            case "tab21":
                              return <ProjectTabSection21 />;
                            case "tab22":
                              return <ProjectTabSection22 />;
                            case "tab23":
                              return <ProjectTabSection23 />;
                            default:
                              return <></>;
                          }
                        })()}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
