import React from "react";

import kanhashanthivanam from "assets/images/bosch/kanha-shanthi-vanam.png";

// import kathriguppevillage from 'assets/images/bosch/kathrivillage.png';

// import coimbatoremetro from 'assets/images/bosch/coimbatore-metro.png';

 import coimbatoresatellite from "assets/images/bosch/cob_plantation_drive.jpg";

import bangaloresatellite from "assets/images/bosch/bangalore-saletallite.png";

import punesoil from "assets/images/bosch/pune-soil.png";

import coimbatoreland from "assets/images/bosch/coimbatore-land.png";

import { Link } from "gatsby";

import Registernowpopup from "components/bosch/registerpopup";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

export default function Wherewhen() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      // partialVisibilityGutter: 30,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        {/* <div className='arrow-navigations custom-arrow-navigation-event'>
          <button className='pr-custom zcaler-top-slide-button' onClick={() => previous()}>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button className='pr-rightcustom zcaler-top-slide-button zscalar-right-custom' onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3 className="title-theme-heading"> Plantation Sites </h3>
        </div>

        <div className="carousel-section">
          <Carousel
            arrows={true}
            autoPlaySpeed={3000}
            draggable
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={true}
            swipeable
            responsive={responsive}
            customButtonGroup={<ButtonGroup />}
          >
            <div className="card where-card bgsw-card bgsw-card-height d-flex align-items-stretch">
              <img
                src={kanhashanthivanam}
                className="img-fluid"
                alt="Shanthivanam"
              />
              <div className="card-body where-body">
                <p className="card-text">
                  <span> Kanha Shanti Vanam, </span> Hyderabad
                </p>
                <p className="card-text">
                  Number of saplings - <span> 15,000 </span>
                </p>
                <p className="card-text">
                  <span> 2 acres, Red loamy soil </span>
                </p>

                {/* <p className='card-text'>
              Date of Plantation - <span> 24th Feb, 2022 </span>
            </p> */}
                <div>
                  <Link
                    className="viewmap-tag"
                    to="https://goo.gl/maps/TUaQbxxnZfgeKEkP7"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Map
                  </Link>
                </div>
              </div>
            </div>
           
            <div className="card where-card bgsw-card  bgsw-card-height d-flex align-items-stretch">
              <img
                src={bangaloresatellite}
                className="img-fluid"
                alt="Kathriguppe Village"
              />
              <div className="card-body where-body">
                <p className="card-text">
                  <span> Kathriguppe Village, </span> Anekal Taluk, Bengaluru
                </p>
                <p className="card-text">
                  Number of saplings - <span> 5,000 </span>
                </p>
                <p className="card-text">
                  <span> 1 acre, Red Sandy soil </span>
                </p>

                {/* <p className='card-text'>
              Date of Plantation - <span> 17th March, 2022 </span>
            </p> */}

                <div>
                  <Link
                    className="viewmap-tag"
                    to="https://goo.gl/maps/UV8yaZuDcrjboDwRA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Map
                  </Link>
                </div>
              </div>
            </div>

            <div className="card where-card bgsw-card bgsw-card-height d-flex align-items-stretch">
              <img
                src={punesoil}
                className="img-fluid"
                alt="Kathriguppe Village"
              />
              <div className="card-body where-body">
                <p className="card-text">
                  <span> Gorhe Khurd Village, </span> Pune
                </p>
                <p className="card-text">
                  Number of saplings - <span> 2,000 </span>
                </p>
                <p className="card-text">
                  <span> 3 acres, hilly terrain, rocky soil </span>
                </p>

                {/* <p className='card-text'>
              Date of Plantation - <span> 17th March, 2022 </span>
            </p> */}

                <div>
                  <Link
                    className="viewmap-tag"
                    to="https://goo.gl/maps/ydutySJgd4esiWiXA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Map
                  </Link>
                </div>
              </div>
            </div>
            <div className="card where-card bgsw-card bgsw-card-height d-flex align-items-stretch">
              <img
                src={coimbatoresatellite}
                className="img-fluid"
                alt="Shanthivanam"
              />
              <div className="card-body where-body">
                <div>
                  <p className="card-text">                 
                  <b>Annur</b>, Coimbatore <br></br>
Plantation activity completed on <b>21st April.</b> <br></br>
Number of saplings - <b>1200</b> <br></br>
Shrubs plantation planned in Aug <br></br>
<b>2 Acres</b>
                  </p>

                {/*  <div>
                  <Link
                    className="viewmap-tag"
                    to="/forests-detail/coimbatore-forest"
                    target="_blank"
                    rel="noreferrer"
                  >
                    know more
                  </Link>
          </div> */}
                  


                   {/*<p className="card-text">
                    Trees - <span> 1000 </span>
                  </p>

                  <p className="card-text">
                    Shrubs - <span> 4000 </span>
                  </p>

                 <Link
                    className="viewmap-tag"
                    to="https://maps.app.goo.gl/yk8XC7u6CE3yvDtv6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Map
          </Link>*/}
                </div>

                <div>
                  {/* <Link className='register-tag' to='https://survey.bosch.com/s.app?A=wQV5UTVb' target='_blank' rel='noreferrer'>
              Register Now
            </Link> */}

                  {/* <p className="card-text">
                    <br></br>
                    <br></br>
                    Due to water scarcity, it has been guided to relocate the
                    plantation site. Relocating the plantation site demonstrates
                    a proactive approach to adapt to the local environmental
                    conditions and maximize the success of the tree planting
                    efforts. The re-plantation would take place in the favorable
                    upcoming months (monsoon). If you’re interested in being a
                    part of this drive,{" "}
                    <Link
                      to="https://forms.office.com/Pages/ResponsePage.aspx?id=GR7lCsgHS067bWSO5YQQ9D5PGsYPRyNOpmx206zXl_BUMTdTVUM4RE81S1cyMzA0VkpGRlhBVVFOUi4u"
                      target="_blank"
                      rel="noreferrer"
                    >
                      click here
                    </Link>{" "}
                    to register.
          </p>  */}
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
