import React, { useState, useEffect } from "react";
import { Link, navigate } from 'gatsby';

import Boschlayout from "../../boschlayout/index";

import SEO from "components/seo/index";

import Boschbanner from "components/bosch/banner";

import Objectforest from "components/bosch/objectiveforest";

import Wherewhen from "components/bosch/where-when";
import GrowthVideo from "components/bosch/gowth-video";

import Projectsunique from "components/bosch/Projects";

import Projectexecute from "components/bosch/project-execute";

import RecentPlantations from "components/bosch/recentplantation";

import SimpleReactLightbox from "simple-react-lightbox";

import Plantationvideo from "components/bosch/plantation-video";


import axios from "axios";

const Boschplantation = () => {

  const [ipAddress, setIpAddress] = useState('');
  const [scrolled, setScrolled] = useState(false);
  

  useEffect(async ()=> {
    try {
      
      const url = 'https://api64.ipify.org?format=json';
      await axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setIpAddress(data.ip);
          getCountry(data.ip);
          
        })
        .catch((error) => {
          console.log("error");
          setScrolled(true);
          
        });
       
        function getCountry(ipAddress){

          if(ipAddress!=''){
            const url = 'https://ipinfo.io/'+ipAddress+'/json?token=301c6b76e03d8e';
          axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
             if(data?.country=='RU' || data?.country=='BY' || data?.country=='SY'
             || data?.country=='KP' || data?.country=='IR'){
              navigate("/404/");
             }else {
              setScrolled(true);
             }

          })
          .catch((error) => {
            console.log("error");
            setScrolled(true);
            
          });
        }
    }
        
      /*  if(ipAddress!=''){
          const url = 'https://ipinfo.io/'+ipAddress+'/json?token=301c6b76e03d8e';
        await axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
         console.log(data);
        });
      }*/
     
    } catch (err) {}
  }, []);


  return (
    <>
      <SEO title="BGSW Forest Plantation Drive" />
      {scrolled==true && (
      <div>
      <Boschlayout >
        <section id="bosch-banner">
          <Boschbanner />
        </section>
        <section id="plantation-video">
          <Plantationvideo />
        </section>
        <section id="objectives-forests">
          <Objectforest />
        </section>
        <section id="where-when">
          <Wherewhen />
        </section>
        <section id="growth-video">
          <GrowthVideo />
        </section>

        <section id="projectsunique">
          <Projectsunique />
        </section>
        <section id="project-execute">
          <Projectexecute />
        </section>
        {
          <SimpleReactLightbox>
            <section id="recentplantation">
              <RecentPlantations />
            </section>
          </SimpleReactLightbox>
        }
      </Boschlayout>
      </div>
      )}
    </>
  );
};

export default Boschplantation;
