import React from "react";
import plantationvector from "assets/images/bosch/plantation-vector.png";
import downarrow from "assets/images/bosch/downarrow.png";

export default function Boschbanner() {
  return (
    <div className="bosch-banner pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <h3 className="title-theme-heading text-bgsw">
              {" "}
              BGSW Forest <span className="dis-block">
                {" "}
                Plantation Drive{" "}
              </span>{" "}
            </h3>

            <p className="content-p-text banner-p-content">
              {" "}
              Bosch Global Software Technologies (formerly known as Robert Bosch
              Engineering and Business Solutions, RBEI) celebrates{" "}
              <span> 25 years of its legacy </span>
              in India in 2022. To commemorate this significant milestone in a
              sustainable manner, we joined hands with Forests By Heartfulness
              (FBH) to plant <span> 25,000+ saplings </span> of forest variety,
              on behalf of our <span> 25,000 associates </span> of BGSW across
              the globe. These saplings were planted across four locations of
              BGSW in India. Hyderabad, Coimbatore, Bangalore and Pune.

            </p>

            <p className="content-p-text banner-p-content">
              {" "}
              These forests were planted to commemorate both the legacy and our
              commitment to <span> alt_future </span> and make it smarter,
              greener and better for everyone; symbolic of sustainability,
              diversity, and longevity.
            </p>
            {/* <img className='arrow-padding' src={downarrow} alt='bannar tree' /> */}
          </div>
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid tree-logo-custom"
              src={plantationvector}
              alt="bannar tree"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
