import React from 'react';

import tab21 from 'assets/images/tabs/tab21.png';

const ProjectTabSection21 = () => {
  return (
    <>
      <div className='tab-wrapper'>
        <div className='img-wrapper img-no-wrapper'>
          <img data-src={tab21} className='lazyload' alt='tab21' />
        </div>
      </div>
    </>
  );
};

export default ProjectTabSection21;
